.maintenancePage {
  background-color: #0071bc;
  background-image: url('https://www.wiser.com/wp-content/uploads/2017/12/404bggraphic.png');
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.row {
  width: 90%;
  margin: 0 auto;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.lightText {
  color: #ffffff;
}

.logoContainer {
  margin: 0 auto 24px auto;
  text-align: center;
}

.logoContainer > img {
  max-width: 145px;
  height: auto;
  margin: 0 auto;
}

/* Font Adjustments */

h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.02em;
  margin: 0 auto 24px auto;
  max-width: 700px;
  font-family: "Proxima Nova", sans-serif;
  line-height: 1.25;
  text-transform: capitalize;
}

p {
  font-size: 21px;
  line-height: 1.25;
  max-width: 700px;
  margin: 0 auto;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 300;
  margin-bottom: 48px;
}